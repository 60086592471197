import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import EventIcon from '@mui/icons-material/Event';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import PlaceIcon from '@mui/icons-material/Place';
import Button from '@mui/material/Button';

import { useNavigate, useParams } from 'react-router-dom';
import useEventCall from '../hooks/useEventCall';
import { useSelector } from 'react-redux';



const EventDetail = () => {

  const {id} = useParams();
  const navigate = useNavigate();
  //const {state:event} = useLocation();
  const {getCurrentEvent} = useEventCall();
  const {currentEvent} = useSelector((state) => state.event)

  useEffect(() => {
    getCurrentEvent(id);
    window.scrollTo(0,0);
  }, [])
  

  return (
    <>
    <Helmet>
      <title>{`${currentEvent ? currentEvent.eventName : 'Event Detail Page'}`}</title>
      <meta name='description' content={currentEvent ? currentEvent.eventDesc : 'Event Detail'} />
    </Helmet>
    <Container maxWidth="md" className='mt-100'>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          {currentEvent?.eventName}
        </Typography>
        <CardMedia
          component="img"
          alt="Event Image"
          height="200"
          image={currentEvent?.eventImage} // Provide the URL of your event image
          style={{ marginBottom: '20px' }}
        />
        <Grid container spacing={2}>
          <Grid item md={10}>
          <Typography variant="body2" color="textSecondary" component="div">
          <EventIcon fontSize="small" /> Date: {currentEvent ? currentEvent.eventDate.split('T')[0]: ''}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          <AttachMoneyIcon fontSize="small" /> Price: {currentEvent?.eventPrice}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
          <PersonIcon fontSize="small" /> Organizer: {currentEvent?.eventOrganizer}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="div">
        <PlaceIcon fontSize='small' /> {currentEvent?.eventLocation}
        </Typography>
          </Grid>
          <Grid item md={2}>
            {
              currentEvent?.eventHasForm && <Button onClick={() => navigate(`/evenemang/${currentEvent.id}/apply`, {state:currentEvent})} variant="outlined">Register</Button>
            }
          
          </Grid>
        </Grid>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {currentEvent?.eventDesc}
        </Typography>
      </Paper>
    </Container>
    </>
  );
};

export default EventDetail;
