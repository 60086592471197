import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
//import Register from "../pages/Register";
import PrivateRouter from "./PrivateRouter";
import Home from "../pages/Home";
import Dashboard from "../components/admin/Dashboard";
import AdminHome from "../components/admin/AdminHome";
import Members from "../components/admin/Members";
import Messages from "../components/admin/Messages";
import Events from "../components/admin/Events";
import NoMatch from "../components/admin/NoMatch";
import Posts from "../components/admin/Posts";
import PageLayout from "../pages/PageLayout";
import EventDetail from "../pages/EventDetail";
import EventForm from "../components/forms/EventForm";
import PostDetail from "../pages/PostDetail";
import PostList from "../pages/PostList";
import About from '../pages/About';
import Contact from '../pages/Contact';
import BecomeMember from "../pages/BecomeMember";
import Calendar from "../pages/Calendar";
import Subscribers from "../components/admin/Subscribers";
import AboutContent from "../components/admin/AboutContent";
import Boards from "../components/admin/Boards";
import FooterContent from "../components/admin/FooterContent";
import ContactContent from "../components/admin/ContactContent";
import Participants from "../components/admin/Participants";
import Partners from "../components/admin/Partners";

const AppRouter = () => {
  return (
    <Router basename="">
      <Routes>
        <Route path="/" element={<PageLayout/>} >
          <Route index={true} element={<Home />} />
          <Route path="/evenemang/:id" element={<EventDetail/>} />
          <Route path="/evenemang/:id/apply" element={<EventForm/>} />
          <Route path="/post/:id" element={<PostDetail/>} />
          <Route path="aktiviteter" element={<PostList/>} />
          <Route path="om-oss" element={<About/>} />
          <Route path="kalender" element={<Calendar/>} />
          <Route path="kontakt" element={<Contact/>} />
          <Route path="kontakt/medlem" element={<BecomeMember/>} />
        </Route>
        
        <Route path="login" element={<Login />} />
        {/* <Route path="register" element={<Register />} /> */}
        <Route path="admin" element={<PrivateRouter />}>
          <Route path="" element={<Dashboard />}>
            <Route index element={<AdminHome />} />
            <Route path="members" element={<Members />} />
            <Route path="messages" element={<Messages />} />
            <Route path="events" element={<Events />} />
            <Route path="participants" element={<Participants />} />
            <Route path="posts" element={<Posts />} />
            <Route path="about" element={<AboutContent />} />
            <Route path="boards" element={<Boards />} />
            <Route path="partners" element={<Partners />} />
            <Route path="footer" element={<FooterContent />} />
            <Route path="contact" element={<ContactContent />} />
            <Route path="subscribers" element={<Subscribers />} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Route>
        <Route path="*" element={ <NoMatch/> }/>
      </Routes>
    </Router>
  );
};

export default AppRouter;