import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    events: [],
    currentEvent: null,
    loading:false,
    error:false,
}

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    fetchStart: (state) => {
        state.loading = true;
        state.error = false;
      },
      loadData: (state, { payload: { data, url } }) => {
        state[url] = data;
        state.loading = false;
      },
      fetchFail: (state) => {
        state.loading = false;
        state.error = true;
      },
  }
});

export const {fetchStart,fetchFail,loadData} = eventSlice.actions

export default eventSlice.reducer